import React, { useState } from 'react';
import Select from './Select.component';

export const SelectSB = (props) => {
  const [ selectedOption, setSelectedOption ] = useState();
  React.useEffect(() => {
    setSelectedOption(props.value);
  }, [props.value]);
  return <Select
    {...props}
    selectedOption={selectedOption}
    setSelectedOption={setSelectedOption}
    getOptionLabel={(option) => option.label}
    onChange={(option) => {
      const newValue = props.options.find((o) => o.value === option);
      props.onChange(newValue || '');
    }}
    disableClearOnBlur
  />;
};
